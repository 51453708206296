.contenedorGrafica{
    background: black;
}
.grafica{
    margin-top: 5px;
    height: 500px;
}

.FirstRow{
    margin-top: 70px;
}
/*@media(max-width: 1105px){
    .FirstRow{
        margin-top: 10%;
    }
}
@media(max-width: 670px){
    .FirstRow{
        margin-top: 18%;
    }
}
@media(max-width: 390px){
    .FirstRow{
        margin-top: 25%;
    }
}
@media(max-width: 292px){
    .FirstRow{
        margin-top: 32%;
    }
}*/

.time{

}
.timeText {
    font-size: 1.2em;
    padding-left: 8em;
}
.cajaRegistro{
    margin-bottom: 5px;
       margin-top: -12px
}
.registros{
    font-size: 12px;
    padding-left: 10em;
}
.panels {
    height: 82px;
    width: 100%;
    padding: 0;
    background: rgb(37, 71, 106);
    color: white;
    border-radius: 4px;
    margin-bottom: 10px;
}
.title {
    width: 100%;
    height: 10%;
    border-radius: 4px 4px 0 0;
    font-size: 1.2em;
    text-align:center;
}
.content {
    width: 100%;
    height: 75%;
    border-radius: 0 0 4px 4px;
    text-align:center;
    font-size: 4em;
}


.texto_info_real_grande{
    font-size: 1.4em !important;
     position: absolute !important;
     top: 25%!important;
}
@media(max-width: 475px){
    .texto_info_real_grande{
        font-size: 1.0em !important;
        top: 25%!important;
    }
}

.DolarAmounts{
   margin-top: 8px;
}
.TitleSection{
    text-align: center;
}

.tamañoForm{
    height: 33px!important;
}

.ident ::-webkit-outer-spin-button,
.ident::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    
}

.ident[type=number] {
    -moz-appearance: textfield;
  }
.LoginContainer{
    width: 30%;
    margin: 5% auto;
}

.RegisterContainer{
    width: 35%;
    margin: auto;
    margin-top: -10px;;
 
}

.Logo{
    width: 100%;
}

@media(min-width:820px) and (max-width:1281px){
    .LoginContainer{
        width: 35%;
    }
}

@media(min-width: 644px) and (max-width:820px){
    .LoginContainer{
        width: 50%;
    }
}

@media(max-width: 640px){
    .LoginContainer{
        width: 80%;
    }
    .RegisterContainer{
        width: 80%;
    }
}
.VDivider {

    border-top: solid 2px #dcdcdc;

    width: 100%;

    margin-top: 15px;

    margin-bottom: 15px;

}



.SetFXLogo {

    width: 54%;



    margin: 1em;

    display: block;

}



.SetTPLogo {

    width: 18%;

    padding: 5px;

    margin: 2px 1em 0 7em;

}



.SFCLogo {

    width: 20%;

    padding: 5px;

    margin: 2px 1em 0 3.5em;

}



.BVCLogo {

    width: 18%;

    margin: 2px 1em 0 3em;

}



.PoweredByEpayco {

    margin-bottom: 10px;

}



.PoweredByEpayco img {

    width: 100%;

}



.DividerOnLargeScreens {

    border-left: solid 1px rgba(0, 0, 0, 0.2) !important;
    display: flex;
    justify-content: center;
    align-items: center;

}



@media (min-width: 200px) and (max-width: 992px) {

    .DividerOnLargeScreens {

        border: none !important;

    }



    .SetFXLogo {

        width: 36%;



        margin: 2px 1em 0 5em;

        display: block;

    }



    .SetTPLogo {

        width: 24%;



        margin: 2px 0.5em 0;

    }



    .SFCLogo {

        width: 26%;



        margin: 2px 0.5em 0 0.1em;

    }



    .BVCLogo {

        width: 24%;

        margin: 2px 1.2em 0 0.1em;

    }

}

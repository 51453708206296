.bvcStocks .Body{
    max-height: 427px;
    min-height: 427px;
    overflow-y: scroll;
}

.BVCLogo{
    width: 50px;
    display: inline-block;
    margin-top: 3px;
    margin-right: 5px;
}
.Inline{
    display: inline;
}
.bvc_texto{

}
.titles{
    font-size: 1.3em;
}
.texts{
    font-size: 16px;
}

@media(max-width: 377px){
    .bvc_texto{
        font-size: 10px;
    }
    /*.BVCLogo{
        display: none;
    }*/
}

@media(max-width: 1178px){
    .BVCLogo{
        display: none;
    }
}

.DolarAmounts .Body {
    padding: 0 30px;
}

.Textos {
    font-size: 16px;
    text-align: left;
}
.CajasTextos {
    font-size: 16px;
}

@media(max-width: 310px) {
    .table_Dolar {
        font-size: 11px;
    }
}

.chartCard {
    width: 100% ;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
}
.chartCard h5{
    padding-left: 2%;
    padding-top: 0.7%;
}
.chartBox {
    width: 100%;
    height: 78%;
    border-radius: 5px;
}
.chartBox2 {
    width: 100%;
    height: 12%;
    border-radius: 5px;
}

.Cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,0);

}

.DolarSpotPanel{
  min-height: 318px
}

.LDSFacebook {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    left: 45%;
    bottom: 45%;
  }
  .LDSFacebook div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #25476A;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .LDSFacebook div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
  }
  .LDSFacebook div:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
  }
  .LDSFacebook div:nth-child(3) {
    left: 45px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }

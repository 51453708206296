.DolarPrice .Body {
    padding: 0 0;
}

.Textos{
    font-size: 16px;
    padding-left: 18px;
    text-align: left;
}
.CajasTextos{
    font-size: 16px;
}
.Label2 {
     font-size: 36px;
 }

.Textos1{
    font-size: 16px;
    padding-left: 18px;

}
.text-main{
    text-align: right;
}
.Estilos{
    text-align: left;
}

.DolarEndDay {
    width: 100%;
}

.DolarEndDay2 {
    width: 100%;
}

.padd20 {
    padding: 0 10%;
}

.AsideChartPanel {
    padding: 15px;
    height: 300px;
}

.SubTitle {
    font-size: 1.5em;
    line-height: 0.8em;
    top: 25%
}

.SubTitleDisclaimer {
    top: 50%;
    position: absolute;
}
.time{
    margin-bottom: 10px;
}
.timeText {
    font-size: 1.2em;
    padding-left: 6.2em;
}


.cajaRegistro{
    margin-bottom: 5px;
    margin-top: -12px
}
.registros{
    font-size: 12px;
    padding-left: 10em;
}
.panels {
    height: 82px;
    width: 100%;
    padding: 0;
    background: rgb(37, 71, 106);
    color: white;
    border-radius: 4px;
    margin-bottom: 8px;
}
.title {
    width: 100%;
    height: 10%;
    border-radius: 4px 4px 0 0;
    font-size: 1.2em;
    text-align:center;
}
.content {
    width: 100%;
    height: 75%;
    border-radius: 0 0 4px 4px;
    text-align:center;
    font-size: 48px;
}


.info_mer :hover{
   background: grey;
}

@media(max-width: 500px) {
    .SubTitle {
        font-size: 1.1em;
        line-height: 1em;
        position: relative !important;
        margin-bottom: 10px
    }
    .SubTitleDisclaimer {
        position: relative !important;
    }
}
.arriba_divs{

}

.grafica {
    height: 500px;
}

@media() {
    .col-md-4 col-sm-12 {
         width: 100% !important;
                height: 75% !important;
    }
}
@media(max-width: 1200px) {
    #tradingview_8e656 {
        width: 100% !important;
        height: 75% !important;
    }
}

.DolarAmounts{
 margin-top:8px;
}

.MainLogo{
    width: 140px;
    z-index: 1;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.HomeRibbon{
    padding-top: 0px !important;
}

/* .HomeRibbon::before{
    height: 80px !important;
} */

.authRibbon{
    height: 3em;
    line-height: 3em;
    background-color: #2C4767;
}

.login{
    margin-right: 20px;
    color: white;
}

.login:hover{
    color: white;
    text-decoration: underline !important;
}

.HeaderButton{
    padding: 15px;
    margin-right: 15px;
}

.ContainerButtons{
    margin-top: -10px;
}

.Separator{
    display: inline-block;
    position: relative;
    height: 40px;
    border-right: solid 2px white;
    margin-right: 15px;
}

.BTNEpayco{
    background-color: #737373;
}

.EpaycoInline{
    display: inline-block;
}

.EpaycoLabel{
    font-size: 13px;
    color: white;
    display: block;
    height: 1em;
    margin-top: -18px;
    margin-bottom: 6px;
}

.ButtonContainer{
    display: inline-flex;
}

.MobileMenu{
    display: none;
}

@media(max-width: 620px){
    .ButtonContainer{
        display: none;
    }

    .ButtonContainerOpen{
        display: inline-flex !important;
    }

    .MobileMenu{
        display: block;
        margin-right: 20px;
        margin-top: 26px;
        color: white !important;
        font-size: 18px;
    }
    .HeaderButton{
        margin-top:16px;
    }
}
